export default function FeaturedBadge({}) {
	return (
		<>
			<div
				className={`absolute z-10 px-2 py-1 transform -skew-x-12 bg-warning -top-6 right-2`}
			>
				<div className="transform skew-x-12">
					<span className="material-icons-outlined text-3xl">
						star
					</span>
				</div>
			</div>
		</>
	);
}
