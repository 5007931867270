import { Link } from '@inertiajs/react';
import FeaturedBadge from '../FeaturedBadge';
import Avatar from '../Avatar';
import parse from 'html-react-parser';
import { capitalize } from 'lodash';

export default function PostCard({
	blog,
	featuredSection = false
}) {
	const {
		title,
		avatar,
		bloggable,
		postedOn,
		tags,
		coverCard,
		featured,
		content,
		type,
		user
	} = blog;

	return (
		<div
			className={`card bg-base-300  shadow-xl hover:-translate-y-2 duration-200 transition-all`}
		>
			<figure className="border-b-2 h-[150px]">
				{featured ? <FeaturedBadge /> : <></>}
				<img src={coverCard} />
			</figure>
			<div
				className="-mt-7 ml-auto mr-8 lg:tooltip"
				data-tip={
					blog.bloggable
						? blog.bloggable.name + ' Post'
						: `${
								blog.user?.first_name +
								' ' +
								blog.user?.last_name
						  } Post`
				}
			>
				<Avatar
					url={bloggable ? avatar : user.avatar}
					className={'w-14 '}
				/>
			</div>

			<div className="card-body pt-0">
				<div className="badge badge-info uppercase text-xs">
					{capitalize(type)}
				</div>
				<h2 className="card-title mt-2 line-clamp-2">
					{title}
				</h2>
				<small className="uppercase text-mute -mt-2">
					{new Date(postedOn).toDateString(
						'en-GB'
					)}
				</small>

				<div className="flex gap-2">
					{JSON.parse(tags).map((tag, i) => (
						<div
							key={i}
							className="badge bg-gray-500 uppercase text-xs"
						>
							{tag}
						</div>
					))}
				</div>

				{featuredSection && (
					<div className="line-clamp-3 no-tailwindcss-base text-mute2 text-sm">
						{parse(content)}
					</div>
				)}
				<div className="card-actions mt-auto">
					<Link
						className="w-full"
						href={route('blogs.show', blog)}
					>
						<button
							className={`w-full mt-4 btn text-white ${
								featured
									? 'btn-warning'
									: 'btn-primary'
							}`}
						>
							Read article
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
